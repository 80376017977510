"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useValidator = exports.useInjectValue = void 0;
const react_hook_form_1 = require("react-hook-form");
const useInjectValue = (setValue) => (name, value) => setValue(name, value, { shouldDirty: false, shouldTouch: false });
exports.useInjectValue = useInjectValue;
const useValidator = (control) => {
    const fn = (name, f) => {
        const value = (0, react_hook_form_1.useWatch)({ control, name });
        const formValues = (0, react_hook_form_1.useWatch)({ control });
        const res = f(value, formValues);
        return res;
    };
    return fn;
};
exports.useValidator = useValidator;
