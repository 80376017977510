import React from 'react'
import { useLocation } from 'react-router-dom'
import { t } from 'i18next'

import {
  Flex,
  NavItem,
  Spacing,
  Text,
  Icon,
  Link
} from '@ui'
import { useRoutingConfig } from '@app/routing'
import {
  debugUtil,
  miscUtil,
  routeUtil,
  calendarUtil,
  useAccessRole
} from '@app/util'
import { PERMISSIONS } from '@app/const'
import { Logo, LogoWhite } from '@app/image'
import connect from './connect'
import {
  Help,
  Me,
  NavPanel,
  WsSelection,
  PaywallItem,
  NotificationItem
} from './components'
import { useChatUnread } from '@app/util/hooks/chat/useChatUnread'

export const Navigation = connect(({
  workspaces,
  workspace,
  workspaceId,
  me,
  auth,
  isPluginEnabled,
  isLoading,
  logout,
  setModal,
  setSidebar,
  subscription,
  organizationId,
  employees,
  positions,
  templates,
  newsFeed,
  isOrgOwner,
  isOrgHidden
}) => {
  const routes = useRoutingConfig({
    auth,
    isPluginEnabled,
    setModal,
    workspaceId,
    workspace,
    me,
    subscription,
    isOrgHidden,
    isOrgOwner
  })
  const { canWrite } = useAccessRole()
  const location = useLocation()
  const isOrg = miscUtil.isDayswapsProInterface()
  const isPaidOrTrial = subscription?.paid || subscription?.trial
  const currentRoutes = routes[isOrg ? 'pro' : 'app']
  const currentPath = currentRoutes.find((route) => location.pathname.includes(route.path))
  const wsName = (isOrg && organizationId) ? me?.organizations && me.organizations.find(org => org.id === organizationId)?.name : (workspace?.name)
  const unread = useChatUnread()

  const numbers = [
    {
      path: '#news-feed',
      number: Object.values(newsFeed).length && newsFeed
        .map((item) => item.targets.map((ns) => ns.userId === me.id && !ns.readOn))
        .flat(1)
        .filter(Boolean).length
    },
    {
      path: '/employees',
      number: employees ? Object.keys(employees).map(id => employees[id]).filter(ee => !ee.external).length : undefined
    },
    {
      path: '/job-positions',
      number: positions ? positions.filter(p => !p.archived).length : undefined
    },
    {
      path: '/localities',
      number: workspace?.localities ? workspace.localities.length : undefined
    },
    {
      path: '/unav-categories',
      number: workspace?.unavailabilityCategories ? workspace.unavailabilityCategories.length : undefined
    },
    {
      path: '/shift-templates',
      number: workspace?.shiftTemplates ? workspace.shiftTemplates.length : undefined
    },
    {
      path: '/day-templates',
      number: templates ? templates.length : undefined
    },
    {
      path: '/cycles',
      number: workspace?.cycles ? workspace.cycles.length : undefined
    },
    {
      path: '/attributes',
      number: workspace?.shiftAttributes ? calendarUtil.getSupportedShiftAttributes(workspace).length : undefined
    },
    {
      path: '/groups',
      number: window.localStorage.getItem('ds-added-groups') ? window.localStorage.getItem('ds-added-groups')?.split(',')?.length : undefined
    }
  ]
  const findNumber = (path) => {
    const item = numbers.find((number) => number.path === path)
    return item?.number || null
  }

  const linkItem = (item) => {
    const allowedSubPages = item.subpages && item.subpages.filter((item) => item.allowed)
    const firstPath = allowedSubPages && item.path + allowedSubPages[0]?.path
    const decideLink = () => {
      if (item.link) return item.link
      if (allowedSubPages) {
        return isOrg ? '/organization' + firstPath : firstPath
      }
      return item.path
    }

    const isChat = item.ico === 'chat'

    return (
      <Link
        key={item.path}
        to={decideLink()}
        type={Link.TYPES.ROUTER_LINK}
      >
        <NavItem
          dark={isOrg}
          ico={item.ico}
          text={t(item.label)}
          isActive={location.pathname.includes(item.path)}
          number={isChat && unread > 0 ? unread : 0}
        />
      </Link>
    )
  }
  const handleDebug = debugUtil.info
  return (
    <NavPanel
      dark={isOrg}
      main={
        <>
          <div>
            <Spacing size={Spacing.SIZES.SIZE_18} />
            <Flex justify={Flex.POSITION.CENTER} grow={1}>
              <Link
                type={Link.TYPES.ON_CLICK}
                onClick={(evt) => {
                  if (evt.ctrlKey || evt.metaKey) {
                    handleDebug()
                    evt.preventDefault()
                    evt.stopPropagation()
                  } else {
                    routeUtil.navigate('/')
                  }
                }}
              >
                {isOrg ? <LogoWhite width='2.5rem' height='3rem' /> : <Logo width='2.5rem' />}
              </Link>
            </Flex>
            <Spacing size={Spacing.SIZES.SIZE_10} />

            {(Boolean(workspaces?.length) || Boolean(Object.keys(me?.acl?.organizations).length)) && (
              <WsSelection
                onClick={() => {
                  setSidebar(null)
                  setModal('workspace-select')
                }}
                isOrg={isOrg}
                wsName={wsName}
              />
            )}
            <Spacing size={Spacing.SIZES.SIZE_12} />
            {isPaidOrTrial && !isOrg && (<NotificationItem />)}
            <Flex
              direction={Flex.DIRECTION.COLUMN}
              align={Flex.POSITION.CENTER}
              justify={Flex.POSITION.SPC_BETWEEN}
            >
              <Flex
                direction={Flex.DIRECTION.COLUMN}
                align={Flex.POSITION.CENTER}
              >
                {currentRoutes
                  .filter((item) => item.allowed && item.label && !item.atBottom)
                  .map((item) => linkItem(item))}
              </Flex>

            </Flex>
          </div>
          <div>
            <Flex
              direction={Flex.DIRECTION.COLUMN}
              align={Flex.POSITION.CENTER}
            >
              {currentRoutes
                .filter((item) => item.allowed && item.label && item.atBottom)
                .map((item) => linkItem(item))}
            </Flex>

            {(
              (isOrgHidden && canWrite(PERMISSIONS.SETTINGS)) ||
              (isOrgOwner && !isOrgHidden)
            ) && (!subscription?.trial) && (
              <Link
                type={Link.TYPES.ON_CLICK}
                onClick={() => { if (!isLoading) miscUtil.openStripeUI() }}
              >
                <NavItem
                  dark={isOrg}
                  ico={isLoading ? Icon.ICONS.loader : Icon.ICONS.payroll}
                  text={t('SUBSCRIPTION')}
                  isActive={false}
                />
              </Link>
            )}

            {((isOrg && isOrgOwner) || (isOrgHidden && canWrite(PERMISSIONS.SETTINGS))) && (
              <PaywallItem
                isOrg={isOrg}
                subscription={subscription}
                isLoading={isLoading}
              />
            )}

            <Help
              className='is-navigation-help'
              anchor={(
                <NavItem
                  ico='help'
                  text={t('SCHEDULE_HELP')}
                  dark={isOrg}
                />
              )}
            />
            <Spacing size={Spacing.SIZES.SIZE_28} />
            <Flex justify={Flex.POSITION.CENTER} grow={1}>
              <Me
                isLoading={isLoading}
                me={me}
                isOrg={isOrg}
                logOut={logout}
                openEmployeeCard={() => setModal('employee-profile', { userId: me.id })}
              />
            </Flex>
            <Spacing size={Spacing.SIZES.SIZE_20} />
          </div>
        </>
      }
      sub={!!currentPath?.subpages && !!currentPath.subpages.length && (
        <Flex
          grow={1}
          direction={Flex.DIRECTION.COLUMN}
        >
          <Spacing size={Spacing.SIZES.SIZE_28}>
            <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL}>
              <Text
                type={Text.TYPES.HEADLINE2}
                text={t(currentPath.label)}
                color={isOrg ? Text.COLORS.WHITE : Text.COLORS.PRIMARY}
              />
            </Spacing>
          </Spacing>
          <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL}>
            {currentPath?.subpages && currentPath.subpages
              .filter((item) => item.allowed)
              .map((item, i) => {
                if (!item.label) return null
                const subItemPath = currentPath.path + item.path
                return (
                  <React.Fragment key={item.path}>
                    {item.groupTitle && (
                      <>
                        {i !== 0 && (<Spacing size={Spacing.SIZES.SIZE_18} />)}
                        <Spacing size={Spacing.SIZES.SIZE_12} type={Spacing.TYPES.HORIZONTAL}>
                          <Text
                            type={Text.TYPES.BODY_LARGE}
                            weight={Text.WEIGHTS.BOLD}
                            color={isOrg ? Text.COLORS.GRAY : Text.COLORS.BLACK}
                            text={t(item.groupTitle)}
                          />
                        </Spacing>
                        <Spacing size={Spacing.SIZES.SIZE_8} />
                      </>
                    )}
                    <Link
                      onClick={item.onClick || undefined}
                      type={item.onClick ? Link.TYPES.ON_CLICK : Link.TYPES.ROUTER_LINK}
                      to={item.onClick ? undefined : (item.disabled ? '#' : (item.link || isOrg ? '/organization' + subItemPath : subItemPath))}
                    >
                      <NavItem
                        dark={isOrg}
                        ico={item.ico}
                        type={NavItem.TYPES.SUB}
                        text={t(item.label)}
                        isActive={location && location.pathname.includes(item.path)}
                        number={findNumber(item.path)}
                        disabled={item.disabled}
                      />
                    </Link>
                  </React.Fragment>
                )
              })}
          </Spacing>
        </Flex>
      )}
    />
  )
})
