"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeInfoHeader = void 0;
const ui_1 = require("@app/component/ui");
const util_1 = require("@app/util");
const react_1 = __importStar(require("react"));
const employee_card_context_1 = require("../../util/employee-card-context");
const useUserDetailsCustomData_1 = require("../../util/hooks/useUserDetailsCustomData");
const EmployeeInfoHeader = () => {
    var _a, _b;
    const { employee, workspace, setHeaderExtraContentTarget } = (0, employee_card_context_1.useEmployeeCard)();
    const headerTarget = (0, react_1.useRef)(null);
    const [customData] = (0, useUserDetailsCustomData_1.useUserDetailsCustomData)();
    // customData?.managedByPlugin
    react_1.default.useEffect(() => {
        if ((0, util_1.isNotNull)(headerTarget.current)) {
            setHeaderExtraContentTarget(headerTarget.current);
        }
    }, [headerTarget.current]);
    return (react_1.default.createElement("div", { className: "ds-ec-info-header" },
        react_1.default.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            react_1.default.createElement(ui_1.Text, { type: ui_1.Text.TYPES.HEADLINE2 }, (_a = employee.user) === null || _a === void 0 ? void 0 :
                _a.lastName,
                " ", (_b = employee.user) === null || _b === void 0 ? void 0 :
                _b.firstName),
            react_1.default.createElement("div", { ref: headerTarget })),
        (0, util_1.isUndefined)(workspace) && (react_1.default.createElement(ui_1.Text, { type: ui_1.Text.TYPES.BODY_MEDIUM }, "User has no workspace")),
        react_1.default.createElement(ui_1.Spacing, { size: ui_1.Spacing.SIZES.SIZE_4 }),
        (0, util_1.isDefined)(customData === null || customData === void 0 ? void 0 : customData.employeeId) && (0, util_1.isNotNull)(customData === null || customData === void 0 ? void 0 : customData.employeeId) && (react_1.default.createElement(ui_1.Text, { color: 'gray', type: ui_1.Text.TYPES.BODY_MEDIUM },
            "ID: ", customData === null || customData === void 0 ? void 0 :
            customData.employeeId))));
};
exports.EmployeeInfoHeader = EmployeeInfoHeader;
